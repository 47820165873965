.triangle-up {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 20px solid rgba(0,0,0,0.30);
}

.triangle-down {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 20px solid rgba(0,0,0,0.30);
}

.box {
    border-bottom: 3px solid rgba(0, 0, 0, 0.1);
}
